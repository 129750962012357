import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    redirect: "/Dashboard",
    children: [
      {
        path: "/OwnerManage",
        name: "OwnerManage",
        component: () => import("../views/OwnerManage.vue"),
      },
      {
        path: "/ErrorPushManage",
        name: "ErrorPushManage",
        component: () => import("../views/ErrorPushManage.vue"),
      },
      {
        path: "/HouseManage",
        name: "HouseManage",
        component: () => import("../views/HouseManage.vue"),
      },
      {
        path: "/TerminalManage",
        name: "TerminalManage",
        component: () => import("../views/TerminalManage.vue"),
      },
      {
        path: "/OperLogManage",
        name: "OperLogManage",
        component: () => import("../views/OperLogManage.vue"),
      },
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
      },
      {
        path: "/UserOfHous",
        name: "UserOfHous",
        component: () => import("../views/UserOfHous.vue"),
      },
      {
        path: "/DeviceOfHous",
        name: "DeviceOfHous",
        component: () => import("../views/DeviceOfHous.vue"),
      },
      {
        path: "/PushLogManage",
        name: "PushLogManage",
        component: () => import("../views/PushLogManage.vue"),
      },
      {
        path: "/StatusManage",
        name: "StatusManage",
        component: () => import("../views/StatusManage.vue"),
      },
      {
        path: "/ErrorManage",
        name: "ErrorManage",
        component: () => import("../views/ErrorManage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.Base_URL),
  routes,
});

export default router;
