import { createStore } from "vuex";

export default createStore({
  state: {
    themeColor: "#189B9C",
    baseImageUrl: "https://www.czjsdkj.cn/backend/jiasudu/images/",
  },
  getters: {},
  mutations: {},
});
