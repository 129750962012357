import { createApp } from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'
import Cookie from 'js-cookie'
// 引入js
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import ECharts from 'vue-echarts';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import 'echarts/lib/chart/line';  // 根据需要引入ECharts的图表类型
import 'echarts/lib/component/tooltip';  // 根据需要引入ECharts的组件
import 'vue-json-pretty/lib/styles.css';
// 引入css
import 'element-plus/dist/index.css'
import './styles/global.css'

const app = createApp(App)
app.use(ECharts)
// 注册组件 
for (const [key, component] of Object.entries(ElementPlusIconsVue)) { app.component(key, component); }
app.component('v-chart', ECharts)
app.use(store)
app.use(NProgress)

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach((to, from) => {
  NProgress.done();
});
app.use(router)
app.use(Cookie)
app.use(ElementPlus, {
  locale: zhCn,
})
app.mount('#app')